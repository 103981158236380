/**
*  This file is loaded due to conventions prior to every `.sass` file.
*
*  Hoisting will not work if the css is included because it comes in last and wins!
*
*  see
*    - https://stackoverflow.com/questions/45598884/change-default-font-in-vuetify
*    - https://github.com/vuetifyjs/vuetify/issues/8169#issuecomment-590010076
*
*/

//
// Change the default font (from Roboto). The font is provided via the 'font-proxima-nova-scss' npm
// package. The font is loaded via the <style> section of the `App.vue` module.
//
// From the docs:
//   > Once installed, create a folder called sass, scss or styles in your src directory with a
//   > file named variables.scss or variables.sass. The vuetify-loader will automatically bootstrap
//   > your variables into Vue CLI’s compilation process, overwriting the framework defaults.
//   >
//   > When you run yarn serve, the vuetify-cli-plugin will automatically hoist the global
//   > Vuetify variables to all of your sass/scss files. When making changes to individual
//   > component variables, you will still need to manually include its variables file.
//
//  see
//   - https://www.w3schools.com/csSref/pr_font_font-family.asp
//
$body-font-family: "Proxima Nova", Roboto, sans-serif;
$heading-font-family: $body-font-family;

//
// Other Global variable
//

//
// The gap used in the layout
//
$var-layout-gap: 1.25rem; // 20px

@import '~@/styles/overrides/v-btn.scss';
@import '~@/styles/overrides/v-dialog.scss';
@import '~@/styles/overrides/v-card.scss';